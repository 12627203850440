.Hovv {
    transition: 0.2s ease-in-out;
}

.Hovv:hover {
    transform: scale(1.1);

}

.Image2 {
    width: 150px;
    height: 150px;
    object-fit: cover;
}



@media (max-width: 680px) {
    .Image2 {
        width: 100px;
        height: 100px;
    }
}