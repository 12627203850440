/* Background blur and full-screen overlay */
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(8px);
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

/* Popup content styles */
.popup-content {
    background-color: white;
    padding: 0px 12px;
    border-radius: 10px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    position: relative;
    max-width: 70%;
    text-align: center;
}

/* Close button styles */
.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.Hov {
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
    transition: 0.2s ease-in-out;
}

.Hov:hover {
    transform: scale(1.1);
    box-shadow: 0px 12px 12px 0px rgba(0, 0, 0, 0.1);
}


.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: var(--height);
    /* Adjust to fit within the row */
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #09f;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}