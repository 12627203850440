.custom-scrollbar-container {
  position: relative;
  height: 50vh;
  width: 100%;
  overflow: hidden;
}

.custom-scrollbar-content {
  height: 100%;
  overflow-y: scroll;
  padding-right: 20px;
  padding-left: 20px;
}

.Padding {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.custom-scrollbar-track {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  height: 100%;
  background-color: #f1f1f1;
  z-index: 1;
}

.custom-scrollbar-content::-webkit-scrollbar {
  display: none;
  /* For Chrome, Safari, and Opera */
}

.custom-scrollbar-thumb {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 64px;
  /* Width of the SVG */
  height: 64px;
  /* Height of the SVG */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 64 64'%3E%3Ccircle cx='32' cy='32' r='32' fill='%23fff'/%3E%3Cpath fill='%2383C909' d='M20.6 38.268c.336.696.923 1.239 1.642 1.52.759.32 1.765.48 3.019.48 1.496 0 2.75-.273 3.76-.82 1.01-.547 1.81-1.5 2.4-2.859.588-1.36.92-3.24.998-5.638h.72c-.106 3.199-.56 5.745-1.361 7.638-.801 1.893-1.907 3.232-3.318 4.017-1.413.786-3.132 1.179-5.158 1.18-2.665 0-4.644-.64-5.938-1.92-1.294-1.28-1.94-3.133-1.938-5.559v-7.157h4.678v6.398c0 1.119.167 2.026.5 2.718zM44.824 25.633c-.335-.696-.922-1.239-1.642-1.52-.759-.32-1.765-.48-3.019-.48-1.496 0-2.75.273-3.76.82-1.01.547-1.81 1.5-2.4 2.859-.588 1.36-.92 3.24-.998 5.638h-.72c.106-3.199.56-5.745 1.361-7.638.801-1.893 1.907-3.232 3.318-4.017 1.413-.786 3.132-1.179 5.158-1.18 2.665 0 4.644.64 5.938 1.92 1.293 1.28 1.94 3.133 1.938 5.559v7.262h-4.678v-6.502c0-1.119-.167-2.025-.5-2.718zm.5 9.916v-7.957h4.678v8.717c0 2.426-.646 4.279-1.938 5.559-1.293 1.28-3.273 1.92-5.94 1.92-2.025 0-3.745-.393-5.158-1.18-1.413-.787-2.519-2.126-3.318-4.018-.8-1.893-1.253-4.438-1.36-7.636h.72c.083 2.4.415 4.279.997 5.638.582 1.36 1.382 2.312 2.4 2.859 1.01.547 2.263.82 3.755.82 1.254 0 2.26-.16 3.02-.48.718-.28 1.304-.823 1.64-1.518.335-.693.502-1.599.5-2.718zM20.1 28.352v6.502h-4.678v-7.262c0-2.424.646-4.276 1.938-5.557 1.293-1.28 3.272-1.92 5.938-1.92 2.025 0 3.745.393 5.158 1.18 1.413.787 2.52 2.126 3.318 4.017.8 1.893 1.253 4.438 1.36 7.636h-.72c-.084-2.4-.416-4.279-.998-5.638-.588-1.36-1.388-2.312-2.4-2.859-1.009-.546-2.263-.82-3.76-.82-1.252 0-2.258.16-3.019.48-.718.28-1.305.823-1.64 1.518-.336.693-.503 1.599-.5 2.718z'/%3E%3C/svg%3E");
  background-size: cover;
  z-index: 2;
}

@media (max-width: 800px) {
  .custom-scrollbar-track {


    left: 10px;


  }

  .Padding {
    padding-top: 0rem;
    padding-bottom: 0rem;
    margin-bottom: 5rem;
  }


  .custom-scrollbar-thumb {

    left: 10px;

    width: 20px;
    /* Width of the SVG */
    height: 20px;
    /* Height of the SVG */
    background-size: cover;

  }

}