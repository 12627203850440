* {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
}

.Link {
  cursor: pointer;
  transition: 0.2s ease-in-out;
  font-weight: 400;
}

.Link:hover {

  transform: scale(1.1);

}


.Margin {
  margin-bottom: 0px;
}

MT {
  margin-top: 10rem;
}

:root {
  --footerText: 18px;
  --scroll: 155px;
  --title: 87px;
  --footer: 65px;
  --height: 230px;
  --height2: 170px;
  --height3: 45px;
  --height4: 50px;
  --team: 230px;
  --row: 4;
  --name: 30px;
  --footer2: 40px;
  --service: 33px;
  --subTitle: 75px;
  --Im: 150px;
  --width: 180px;
  --width2: 88px;
  --text: 22px;
  --blue: #080852;
  --sec: #160E37;
  --black: #191919;
  --green: #82C809;
}

.Im {
  width: 200px;
}

.line {
  line-height: 80px;
}

@media (max-width: 1440px) {
  :root {
    --subTitle: 50px;
    --width2: 56px;
  }
}

@media (max-width : 600px) {
  :root {
    --scroll: 38px;
    --footer: 30px;
    --footer2: 26px;
    --height3: 20px;
    --height: 100px;
    --height2: 50px;
    --height4: 30px;
    --Im: 50px;
    --name: 20px;
    --service: 18px;
    --width: 44.5px;
    --width2: 26px;
    --title: 32px;
    --subTitle: 22px;
    --row: 2;
    --text: 14px;
    --footerText: 14px;
    --team: 120px;
  }

  .Margin {
    margin-bottom: -40px;

  }

  .MT {
    margin-top: 0rem;
  }

  .Margin2 {
    margin-bottom: -50px;

  }


  .line {
    line-height: normal;
  }

  .Im {
    width: 100px;
  }

  .loading-container {

    height: 100vh !important;
    background-color: var(--blue);


  }

  .loading-gif {
    width: 100% !important;

    height: auto !important
  }

}

.container {
  max-width: 1440px !important;
}

.fade-in-element {
  opacity: 0;
  transition: opacity 1s ease-in-out, bottom 1s ease-in-out;
  position: relative;
  bottom: -100px;

  width: auto;
  margin-top: 1rem;


}

.fade-in-element hr {
  width: 0%;
  transition: 1s ease-in-out;

}

.fade-in-visible {
  opacity: 1;
  bottom: 0;


}

.fade-in-visible hr {
  width: 100%;

}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  /* Full height */
  background-color: var(--blue);
  /* Optional background */
}

.loading-gif {
  width: 100%;
  /* Adjust size as needed */
  height: 100%;
}