
.word-slider {
    height: 155px;

    overflow: hidden;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    scroll-snap-type: y mandatory;

}

.words {
    display: flex;
    flex-direction: column;
    transition: transform 0.4s cubic-bezier(0.25, 1.25, 0.5, 1.0);
   
}

.words div {
    height: 155px;
    
    line-height: 155px;
    text-align: left;
    font-size: inherit;
    display: flex;
    align-items: center;
    justify-content: start;
    font-weight: bold;
}


.Button {
    transition: 0.2s ease-in-out;
    color: var(--black);
    border-radius: 5px;
    border: none;
    width: 50%;
}

.Button span {
    transition: 0.2s ease-in-out;
    color: var(--green);

}





.Button:hover {
    background-color: var(--blue);
    color: white !important;
}

.Button2 {
    transition: 0.2s ease-in-out;
    color: white;
    background-color: var(--green);
    border: none;
    border-radius: 5px;
    width: 50%;

}

.Button2:hover {
    background-color: white;
    color: var(--blue) !important;
}

.Button2:hover span {
    color: var(--green) !important;
}

.Button2 span {
    transition: 0.2s ease-in-out;
    color: var(--blue);
}

/* Background blur and full-screen overlay */
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(8px);
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}


.popup-content {
    background-color: white;
    padding: 0px 12px;
    border-radius: 10px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    position: relative;
    max-width: 70%;
    text-align: center;
}

/* Close button styles */
.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.Hov {
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
    transition: 0.2s ease-in-out;
}

.Hov:hover {
    transform: scale(1.1);
    box-shadow: 0px 12px 12px 0px rgba(0, 0, 0, 0.1);
}

input {
    background-color: #F2F2F2;
    border: none;
    border-radius: 5px;
}

textarea {
    background-color: #F2F2F2;
    border: none;
    border-radius: 5px;

}

.Submit {
    transition: 0.2s ease-in-out;
}

.Submit:hover {
    background-color: white !important;
    color: var(--green) !important;
}


.fade-left {
    opacity: 0;
    position: relative;
    left: -100px;
    transition: 0.8s ease-in-out;

}

.fade-in-left {
    opacity: 1 !important;
    left: 0px !important;
}

.fade-right {
    opacity: 0;
    position: relative;
    left: 100px;
    transition: 0.8s ease-in-out;
}

.fade-in-right {
    opacity: 1 !important;
    left: 0px !important;
}

@media(max-width: 680px) {
    .fade-left {
        opacity: 0;
        left: 0px;
        transition: 0.8s ease-in-out;

    }

    .popup-content {

        max-width: 90% !important;

    }

    .fade-in-left {
        opacity: 1 !important;

    }

    .fade-right {
        opacity: 0;
        left: 0px;
        transition: 0.8s ease-in-out;
    }

    .fade-in-right {
        opacity: 1 !important;
        left: 0px !important;
    }
    .word-slider {
        height: 50px;
    
        overflow: hidden;
        position: relative;
        display: inline-block;
        vertical-align: middle;
        scroll-snap-type: y mandatory;
    
    }
    
    .words {
        display: flex;
        flex-direction: column;
        transition: transform 0.4s cubic-bezier(0.25, 1.25, 0.5, 1.0);
       
    }
    
    .words div {
        height: 50px;
        
        line-height: 50px;
        text-align: left;
        font-size: inherit;
        display: flex;
        align-items: center;
        justify-content: start;
        font-weight: bold;
    }
}